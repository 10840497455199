
function getPagingParameters(
    activeTabId,
    navName,
    mainDiv,
    tableName,
    expired,
    sectionCounter,
    tbodyName,
    resultsTable,
    loadPageFunction
) {
    var pagingparameters = {};
    var uniqueSuffix = '_' + sectionCounter + '_' + activeTabId;

    pagingparameters.navName = navName;
    pagingparameters.mainDiv = mainDiv;
    pagingparameters.tableName = tableName;
    pagingparameters.expired = expired;
    pagingparameters.sectionCounter = sectionCounter;
    pagingparameters.tbodyName = tbodyName;
    pagingparameters.resultsTable = resultsTable;
    pagingparameters.sessiondatanamewithoutdot = 'rowClass' + uniqueSuffix;
    pagingparameters.divName = 'navDiv' + uniqueSuffix;
    pagingparameters.tError = 'rowError' + uniqueSuffix;
    pagingparameters.activeTabId = activeTabId;
    pagingparameters.totalrecs = 0;
    pagingparameters.loadPageFunction = loadPageFunction;
    pagingparameters.currentPage = 1;
    return pagingparameters;

} // parameters for paging

/* 08/18/2011 --  moved the varaible from pagingcommon function for drop down selection functionality to work */
var Norec;
/*  Change the value below to set page size.   */
var pagingSize = 25;
var noofrecs = pagingSize;



function pagingcommon(customParameters) {

    /*
    $("#" + customParameters.tError).remove();
    */

    var rowsShown = noofrecs;

    /* non-existent element:
    $('#rowsShown').val(rowsShown);
    */

    var rowsTotal = $('#' + customParameters.tbodyName + ' tr').length;

    if ((rowsTotal == 1) && ($("#" + customParameters.tError).length > 0)) {
        return;
    }

    var currentSuffix = customParameters.sectionCounter + '_' + customParameters.activeTabId;

    var first1 = 'first' + currentSuffix;
    var next1 = 'next' + currentSuffix;
    var prev1 = 'prev' + currentSuffix;
    var last1 = 'last' + currentSuffix;
    var Norec = 'norec' + currentSuffix;
    var pageId = 'page' + currentSuffix;

    var currPage = 0;
    var pageNum = 0;
    $("#" + pageId).html('');
    if (rowsTotal > rowsShown) {

        $(customParameters.navName).after("" +
            "<div class='fieldset' id ='" + pageId + "'>" +
                "<div class='fieldset' id = '" + customParameters.mainDiv + "'>" +
                    "<div class='table'>" +
                        "<table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" bordercolor=\"red\" class='pagingTable' id ='" + customParameters.tableName + "' align='center' width='100%'>" +
                            "<tbody class ='headerRow'></tbody>" +
                            "<tfoot><tr><td style='width:20%'></td>" +
            "<td>&nbsp;</td><td align=\"right\">  <input type ='button' id ='" + first1 +
            "' value='<<' style='background:none; border:0;color:grey;font-size:12px;font-weight:bold;'   tooltip='move to first page'  disabled='disabled' />" +
            "&nbsp;<input type ='button' id ='" + prev1 +
            "' value = 'Prev' style='background:none; border:0;color:grey;font-size:12px;font-weight:bold;' tooltip='move to previous page' disabled='disabled'/>&nbsp;</td>" +
            "<td  ><div id='" + customParameters.divName +
            "' align ='center' style='width:100%'></div></td><td>&nbsp;" +
            "<input type ='button' id ='" + next1 +
            "' value ='Next' style='background:none; border:0;color:blue;font-size:12px;font-weight:bold;' tooltip='move to next page'  />&nbsp;" +
            "<input type ='button' id ='" + last1 +
            "' value='>>'style='background:none; border:0;color:blue;font-size:12px;font-weight:bold;' tooltip='move to last page'  /></td>" +
            "<td style='width:30%;font-size:12px;font-weight:bold;' align='center'>No of Records:&nbsp;&nbsp;<select id='" + Norec +
            "'  ><option value='" + pagingSize + "'>" + pagingSize + "</option><option value='" + (pagingSize * 2) + "'>" + (pagingSize * 2) + "</option><option value='" + (pagingSize * 4) + "'>" + (pagingSize * 4) + "</option></select></td></tr></tfoot></table></div></div></div>");

        $('#' + Norec + ' option[value=' + noofrecs + ']').attr('selected', true);
        var numPages = Math.ceil(rowsTotal / rowsShown);
        var numPages1 = parseInt(numPages);

        for (i = 0; i < numPages; i++) {
            pageNum = i + 1;
            var anchorId = 'anchor' + pageNum + '_' + currentSuffix;
            $("#" + customParameters.divName).append(
                    "<a href='#' rel='" + i + "' id='" + anchorId + "' >" +
                        "<span style='color:blue;text-align:center;font-size:12px;'>" + pageNum + "</span>" +
                    "</a> &nbsp;");
        }

        $('#' + customParameters.tbodyName + ' tr').hide();
        $('.' + customParameters.sessiondatanamewithoutdot + " :first").show();
        $('#' + customParameters.tbodyName + ' tr').slice(0, rowsShown).show();

        $("#" + customParameters.divName + " a:first").addClass("selectedPage");


        $("#" + customParameters.divName + " a").bind("click", function () {

            $('#' + customParameters.divName + ' > a.selectedPage').removeClass('selectedPage');

            $(this).addClass('selectedPage');

            currPage = $(this).attr("rel");


            var startItem = currPage * rowsShown;
            var endItem = parseInt(startItem) + parseInt(rowsShown);
            $('#' + customParameters.tbodyName + ' tr').hide();
            $('#' + customParameters.tbodyName + ' tr').slice(startItem, endItem).show();


            $('#' + customParameters.tbodyName + ' tr:first').parent().trigger('applyWidgets');

            if (typeof (tabbar) != "undefined") {
                invokeIframeResizer(tabbar.getActiveTab());
            }
            var currPage1 = parseInt(currPage) + 1;

            if (currPage1 > 1) {
                $("#" + first1).removeAttr("disabled");
                $("#" + first1).attr("src", "Images/first_1.png");
                $("#" + prev1).removeAttr("disabled");
                $("#" + prev1).attr("src", "Images/prev_1.png");


                $("#" + first1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
                $("#" + prev1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");

            }
            else {
                $("#" + first1).attr("disabled", "disabled");
                $("#" + first1).attr("src", "Images/first_dis.png");
                $("#" + prev1).attr("disabled", "disabled");
                $("#" + prev1).attr("src", "Images/prev_dis.png");

                $("#" + first1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");
                $("#" + prev1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");

            }
            if (currPage1 == numPages1) {
                $("#" + next1).attr("disabled", "disabled");
                $("#" + next1).attr("src", "Images/next_dis.png");
                $("#" + last1).attr("disabled", "disabled");
                $("#" + last1).attr("src", "Images/last_dis.png");

                $("#" + next1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");
                $("#" + last1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");

            }
            else {

                $("#" + next1).removeAttr("disabled");
                $("#" + next1).attr("src", "Images/next_1.png");
                $("#" + last1).removeAttr("disabled");
                $("#" + last1).attr("src", "Images/last_1.png");

                $("#" + next1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
                $("#" + last1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
            }
            if (typeof (tabbar) != "undefined") {
                invokeIframeResizer(tabbar.getActiveTab());
            }
        });


        //invokeIframeResizer(tabbar.getActiveTab());


        $("#" + next1).bind("click", function () {
            var nextPageLink = $('#' + customParameters.divName + ' > a.selectedPage').next('a');

            $('#' + customParameters.divName + ' > a.selectedPage').removeClass('selectedPage');

            $(nextPageLink).addClass('selectedPage');

            var rowsTotal = $('#' + customParameters.tbodyName + ' tr').length;
            var newPage = parseInt(currPage) + 1;
            var startItem = newPage * rowsShown;
            var endItem = parseInt(startItem) + parseInt(rowsShown);
            $('#' + customParameters.tbodyName + ' tr').hide();
            $('#' + customParameters.tbodyName + ' tr').slice(startItem, endItem).show();

            $('#' + customParameters.tbodyName + ' tr:first').parent().trigger('applyWidgets');
            currPage = parseInt(currPage) + 1;
            var currPage1 = parseInt(currPage) + 1;

            if (currPage1 >= 1) {
                $("#" + first1).removeAttr("disabled");
                $("#" + first1).attr("src", "Images/first_1.png");
                $("#" + prev1).removeAttr("disabled");
                $("#" + prev1).attr("src", "Images/prev_1.png");


                $("#" + first1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
                $("#" + prev1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");

            }
            else {
                $("#" + first1).attr("disabled", "disabled");
                $("#" + first1).attr("src", "Images/first_dis.png");
                $("#" + prev1).attr("disabled", "disabled");
                $("#" + prev1).attr("src", "Images/prev_dis.png");

                $("#" + first1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");
                $("#" + prev1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");

            }
            if (currPage1 >= numPages1) {
                $("#" + next1).attr("disabled", "disabled");
                $("#" + next1).attr("src", "Images/next_dis.png");
                $("#" + last1).attr("disabled", "disabled");
                $("#" + last1).attr("src", "Images/last_dis.png");

                $("#" + next1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");
                $("#" + last1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");

            }
            else {

                $("#" + next1).removeAttr("disabled");
                $("#" + next1).attr("src", "Images/next_1.png");
                $("#" + last1).removeAttr("disabled");
                $("#" + last1).attr("src", "Images/last_1.png");

                $("#" + next1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
                $("#" + last1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
            }
            $('body,html').animate({ scrollTop: 0 }, 800);

            if (typeof (tabbar) != "undefined") {
                invokeIframeResizer(tabbar.getActiveTab());
            }

        });

        $("#" + prev1).bind("click", function () {

            var previous = $('#' + customParameters.divName + ' > a.selectedPage').prev('a');

            $('#' + customParameters.divName + ' > a.selectedPage').removeClass('selectedPage');

            $(previous).addClass('selectedPage');

            var rowsTotal = $('#' + customParameters.tbodyName + ' tr').length;
            var newPage = parseInt(currPage) - 1;
            var startItem = newPage * rowsShown;
            var endItem = parseInt(startItem) + parseInt(rowsShown);
            $('#' + customParameters.tbodyName + ' tr').hide();
            $('#' + customParameters.tbodyName + ' tr').slice(startItem, endItem).show();

            $('#' + customParameters.tbodyName + ' tr:first').parent().trigger('applyWidgets');


            var currPage1 = parseInt(currPage) - 1;
            currPage = parseInt(currPage) - 1;

            if (currPage1 >= 1) {
                $("#" + first1).removeAttr("disabled");
                $("#" + first1).attr("src", "Images/first_1.png");
                $("#" + prev1).removeAttr("disabled");
                $("#" + prev1).attr("src", "Images/prev_1.png");

                $("#" + first1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
                $("#" + prev1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");

            }
            else {
                $("#" + first1).attr("disabled", "disabled");
                $("#" + first1).attr("src", "Images/first_dis.png");
                $("#" + prev1).attr("disabled", "disabled");
                $("#" + prev1).attr("src", "Images/prev_dis.png");

                $("#" + first1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");
                $("#" + prev1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");

            }
            if (currPage1 >= numPages1) {
                $("#" + next1).attr("disabled", "disabled");
                $("#" + next1).attr("src", "Images/next_dis.png");
                $("#" + last1).attr("disabled", "disabled");
                $("#" + last1).attr("src", "Images/last_dis.png");

                $("#" + next1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");
                $("#" + last1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");

            }
            else {

                $("#" + next1).removeAttr("disabled");
                $("#" + next1).attr("src", "Images/next_1.png");
                $("#" + last1).removeAttr("disabled");
                $("#" + last1).attr("src", "Images/last_1.png");

                $("#" + next1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
                $("#" + last1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
            }
            $('body,html').animate({ scrollTop: 0 }, 800);
            if (typeof (tabbar) != "undefined") {
                invokeIframeResizer(tabbar.getActiveTab());
            }
        });


        $("#" + first1).bind("click", function () {

            $('#' + customParameters.divName + ' > a.selectedPage').removeClass('selectedPage');

            $("#" + customParameters.divName + " a:first").addClass("selectedPage");

            var rowsTotal = $('#' + customParameters.tbodyName + ' tr').length;
            var newPage = 0;
            currPage = 0;
            var startItem = newPage * rowsShown;
            var endItem = parseInt(startItem) + parseInt(rowsShown);
            $('#' + customParameters.tbodyName + ' tr').hide();
            $('#' + customParameters.tbodyName + ' tr').slice(startItem, endItem).show();

            $('#' + customParameters.tbodyName + ' tr:first').parent().trigger('applyWidgets');


            var currPage1 = 0;

            if (currPage1 >= 1) {
                $("#" + first1).removeAttr("disabled");
                $("#" + first1).attr("src", "Images/first_1.png");
                $("#" + prev1).removeAttr("disabled");
                $("#" + prev1).attr("src", "Images/prev_1.png");

                $("#" + first1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
                $("#" + prev1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");

            }
            else {
                $("#" + first1).attr("disabled", "disabled");
                $("#" + first1).attr("src", "Images/first_dis.png");
                $("#" + prev1).attr("disabled", "disabled");
                $("#" + prev1).attr("src", "Images/prev_dis.png");

                $("#" + first1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");
                $("#" + prev1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");

            }
            if (currPage1 >= numPages1) {
                $("#" + next1).attr("disabled", "disabled");
                $("#" + next1).attr("src", "Images/next_dis.png");
                $("#" + last1).attr("disabled", "disabled");
                $("#" + last1).attr("src", "Images/last_dis.png");

                $("#" + next1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");
                $("#" + last1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");

            }
            else {

                $("#" + next1).removeAttr("disabled");
                $("#" + next1).attr("src", "Images/next_1.png");
                $("#" + last1).removeAttr("disabled");
                $("#" + last1).attr("src", "Images/last_1.png");

                $("#" + next1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
                $("#" + last1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
            }
            $('body,html').animate({ scrollTop: 0 }, 800);
            if (typeof (tabbar) != "undefined") {
                invokeIframeResizer(tabbar.getActiveTab());
            }
        });

        $("#" + last1).bind("click", function () {

            $('#' + customParameters.divName + ' > a.selectedPage').removeClass('selectedPage');

            $("#" + customParameters.divName + " a:last").addClass("selectedPage");

            var rowsTotal = $('#' + customParameters.tbodyName + ' tr').length;
            var newPage = Math.ceil(rowsTotal / rowsShown);
            currPage = parseInt(newPage) - 1;
            var startItem = parseInt(newPage - 1) * rowsShown;
            var endItem = parseInt(startItem) + parseInt(rowsShown);
            $('#' + customParameters.tbodyName + ' tr').hide();
            $('#' + customParameters.tbodyName + ' tr').slice(startItem, endItem).show();

            $('#' + customParameters.tbodyName + ' tr:first').parent().trigger('applyWidgets');

            var currPage1 = parseInt(newPage);

            if (currPage1 >= 1) {
                $("#" + first1).removeAttr("disabled");
                $("#" + first1).attr("src", "Images/first_1.png");
                $("#" + prev1).removeAttr("disabled");
                $("#" + prev1).attr("src", "Images/prev_1.png");

                $("#" + first1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
                $("#" + prev1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");

            }
            else {
                $("#" + first1).attr("disabled", "disabled");
                $("#" + first1).attr("src", "Images/first_dis.png");
                $("#" + prev1).attr("disabled", "disabled");
                $("#" + prev1).attr("src", "Images/prev_dis.png");

                $("#" + first1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");
                $("#" + prev1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");

            }
            if (currPage1 >= numPages1) {
                $("#" + next1).attr("disabled", "disabled");
                $("#" + next1).attr("src", "Images/next_dis.png");
                $("#" + last1).attr("disabled", "disabled");
                $("#" + last1).attr("src", "Images/last_dis.png");

                $("#" + next1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");
                $("#" + last1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");

            }
            else {

                $("#" + next1).removeAttr("disabled");
                $("#" + next1).attr("src", "Images/next_1.png");
                $("#" + last1).removeAttr("disabled");
                $("#" + last1).attr("src", "Images/last_1.png");

                $("#" + next1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
                $("#" + last1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
            }
            $('body,html').animate({ scrollTop: 0 }, 800);
            if (typeof (tabbar) != "undefined") {
                invokeIframeResizer(tabbar.getActiveTab());
            }
        });

    }
    else if (rowsTotal > 0) {

        $(customParameters.navName).after("<div class='fieldset' id ='" + pageId +
                "'>" +

                "<div class='fieldset' id = '" + customParameters.mainDiv + "'><div class='table'><table cellpadding=\"0\" cellspacing=\"0\" class='pagingTable' id ='" + customParameters.tableName + "' align='center'  width='100%' class ='headerRow1'><tbody id='Tbody1' class ='headerRow'></tbody>" +
                "<tfoot><tr><td style='width:40%'></td>" +
            "<td>&nbsp;</td><td>&nbsp;</td>" +
            "<td style='width:30%' ></div></td><td>&nbsp;" +
            "</td>" +
            "<td style='width:30%;font-size:12px;font-weight:bold;'>No of Records: &nbsp;<select id='" + Norec + "' ><option value='" + pagingSize + "'>" + pagingSize + "</option><option value='" + (pagingSize * 2) + "'>" + (pagingSize * 2) + "</option><option value='" + (pagingSize * 4) + "'>" + (pagingSize * 4) + "</option></select></td></tr></tfoot></table></div></div>");
        $('#' + customParameters.tbodyName + ' tr').slice(0, rowsTotal).show();

        $('#' + customParameters.tbodyName + ' tr:first').parent().trigger('applyWidgets');

        //if (rowsTotal > noofrecs ) {

        $('#' + Norec + ' option[value=' + noofrecs + ']').attr('selected', true);

        //}


        if (pagingSize >= rowsTotal) {
            $("#" + Norec).attr("disabled", "disabled");
        }
    }
    else {
        //displayError(customParameters);



    }
    $("#" + Norec).bind("change", function () {

        var text1 = document.getElementById(Norec);
        noofrecs = text1.options[text1.selectedIndex].text;

        //$("#" + customParameters.mainDiv).hide();
        //$("#" + customParameters.mainDiv).remove();
        //$('#page0_SS').remove();
        $("#" + pageId).remove();

        pagingcommon(customParameters);
        $('.' + customParameters.sessiondatanamewithoutdot + " :first").show();

        $('#' + customParameters.tbodyName + ' tr:first').parent().trigger('applyWidgets');




        // Handle tab bar resizing.
        var obj = document.getElementById("a_tabbar");
        if (obj == null) {

            //do nothing at this time.
        }
        else {
            resizetab(tabbar.getActiveTab());

        }
        $('body,html').animate({ scrollTop: 0 }, 800);
        if (typeof (tabbar) != "undefined") {
            invokeIframeResizer(tabbar.getActiveTab());
        }
    });                         // on change functionality for selection of records to display

    if (typeof (tabbar) != "undefined") {
        invokeIframeResizer(tabbar.getActiveTab());
    }
} // paging


// This paging function is used for create test session page - load on demand.
function pagingcommonNew(customParameters) {

    /*
    $("#" + customParameters.tError).remove();
    */

    var rowsShown = noofrecs;

    /* non-existent element:
    $('#rowsShown').val(rowsShown);
    */

    var rowsTotal = customParameters.totalrecs;

    if ((rowsTotal == 1) && ($("#" + customParameters.tError).length > 0)) {
        return;
    }

    var currentSuffix = customParameters.sectionCounter + '_' + customParameters.activeTabId;

    var first1 = 'first' + currentSuffix;
    var next1 = 'next' + currentSuffix;
    var prev1 = 'prev' + currentSuffix;
    var last1 = 'last' + currentSuffix;
    var Norec = 'norec' + currentSuffix;
    var pageId = 'page' + currentSuffix;

    var currPage = customParameters.currentPage;
    var pageNum = 0;
    $("#" + pageId).html('');
    if (rowsTotal > rowsShown) {

        $(customParameters.navName).after("" +
            "<div class='fieldset' id ='" + pageId + "'>" +
                "<div class='fieldset' id = '" + customParameters.mainDiv + "'>" +
                    "<div class='table'>" +
                        "<table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" bordercolor=\"red\" class='pagingTable' id ='" + customParameters.tableName + "' align='center' width='100%'>" +
                            "<tbody class ='headerRow'></tbody>" +
                            "<tfoot><tr><td style='width:20%'></td>" +
            "<td>&nbsp;</td><td align=\"right\">  <input type ='button' id ='" + first1 +
            "' value='<<' style='background:none; border:0;color:grey;font-size:12px;font-weight:bold;'   tooltip='move to first page'  disabled='disabled' />" +
            "&nbsp;<input type ='button' id ='" + prev1 +
            "' value = 'Prev' style='background:none; border:0;color:grey;font-size:12px;font-weight:bold;' tooltip='move to previous page' disabled='disabled'/>&nbsp;</td>" +
            "<td  ><div id='" + customParameters.divName +
            "' align ='center' style='width:100%'></div></td><td>&nbsp;" +
            "<input type ='button' id ='" + next1 +
            "' value ='Next' style='background:none; border:0;color:blue;font-size:12px;font-weight:bold;' tooltip='move to next page'  />&nbsp;" +
            "<input type ='button' id ='" + last1 +
            "' value='>>'style='background:none; border:0;color:blue;font-size:12px;font-weight:bold;' tooltip='move to last page'  /></td>" +
            "<td style='width:30%;font-size:12px;font-weight:bold;' align='center'>No of Records:&nbsp;&nbsp;<select id='" + Norec +
            "'  ><option value='" + pagingSize + "'>" + pagingSize + "</option><option value='" + (pagingSize * 2) + "'>" + (pagingSize * 2) + "</option><option value='" + (pagingSize * 4) + "'>" + (pagingSize * 4) + "</option></select></td></tr></tfoot></table></div></div></div>");

        $('#' + Norec + ' option[value=' + noofrecs + ']').attr('selected', true);
        var numPages = Math.ceil(rowsTotal / rowsShown);
        var numPages1 = parseInt(numPages);
        
        for (i = 0; i < numPages; i++) {
            pageNum = i + 1;
            var anchorId = 'anchor' + pageNum + '_' + currentSuffix;

            $("#" + customParameters.divName).append(
                    "<a href='#' rel='" + i + "' id='" + anchorId + "' >" +
                        "<span " + "   style='color:blue;text-align:center;font-size:12px;'>" + pageNum + "</span>" +
                    "</a> &nbsp;");
        }


        $('#' + customParameters.tbodyName + ' tr').hide();
        $('.' + customParameters.sessiondatanamewithoutdot + " :first").show();
        $('#' + customParameters.tbodyName + ' tr').slice(0, rowsShown).show();

        if (customParameters.currentPage == 1)
            $("#" + customParameters.divName + " a:first").addClass("selectedPage");
        else
            $("#" + customParameters.divName + " a:eq(" + (customParameters.currentPage-1) + ")").addClass("selectedPage");
        var currPage1 = parseInt(customParameters.currentPage) ;

        if (currPage1 > 1) {
            $("#" + first1).removeAttr("disabled");
            $("#" + first1).attr("src", "Images/first_1.png");
            $("#" + prev1).removeAttr("disabled");
            $("#" + prev1).attr("src", "Images/prev_1.png");


            $("#" + first1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
            $("#" + prev1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");

        }
        else {
            $("#" + first1).attr("disabled", "disabled");
            $("#" + first1).attr("src", "Images/first_dis.png");
            $("#" + prev1).attr("disabled", "disabled");
            $("#" + prev1).attr("src", "Images/prev_dis.png");

            $("#" + first1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");
            $("#" + prev1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");

        }
        if (currPage1 == numPages1) {
            $("#" + next1).attr("disabled", "disabled");
            $("#" + next1).attr("src", "Images/next_dis.png");
            $("#" + last1).attr("disabled", "disabled");
            $("#" + last1).attr("src", "Images/last_dis.png");

            $("#" + next1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");
            $("#" + last1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");

        }
        else {

            $("#" + next1).removeAttr("disabled");
            $("#" + next1).attr("src", "Images/next_1.png");
            $("#" + last1).removeAttr("disabled");
            $("#" + last1).attr("src", "Images/last_1.png");

            $("#" + next1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
            $("#" + last1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
        }

        $("#" + customParameters.divName + " a").bind("click", function () {

            $('#' + customParameters.divName + ' > a.selectedPage').removeClass('selectedPage');

            $(this).addClass('selectedPage');

            currPage = $(this).attr("rel");
            
            var startItem = currPage * rowsShown;
            var endItem = parseInt(startItem) + parseInt(rowsShown);
            $('#' + customParameters.tbodyName + ' tr').hide();
            $('#' + customParameters.tbodyName + ' tr').slice(startItem, endItem).show();


            $('#' + customParameters.tbodyName + ' tr:first').parent().trigger('applyWidgets');

            if (typeof (tabbar) != "undefined") {
                invokeIframeResizer(tabbar.getActiveTab());
            }
            
            if (typeof (tabbar) != "undefined") {
                invokeIframeResizer(tabbar.getActiveTab());
            }

            // Must reload the new page.              
            customParameters.loadPageFunction(Number(currPage) + 1);
            //LoadCreatedSessionPage(Number(currPage) + 1);
        });


        //invokeIframeResizer(tabbar.getActiveTab());



        $("#" + next1).bind("click", function () {
            var nextPageLink = $('#' + customParameters.divName + ' > a.selectedPage').next('a');

            $('#' + customParameters.divName + ' > a.selectedPage').removeClass('selectedPage');

            $(nextPageLink).addClass('selectedPage');

            var rowsTotal = $('#' + customParameters.tbodyName + ' tr').length;
            var newPage = parseInt(currPage) + 1;
            var startItem = newPage * rowsShown;
            var endItem = parseInt(startItem) + parseInt(rowsShown);
            $('#' + customParameters.tbodyName + ' tr').hide();
            $('#' + customParameters.tbodyName + ' tr').slice(startItem, endItem).show();

            $('#' + customParameters.tbodyName + ' tr:first').parent().trigger('applyWidgets');
            currPage = parseInt(currPage) + 1;
            var currPage1 = parseInt(currPage) + 1;

            if (currPage1 >= 1) {
                $("#" + first1).removeAttr("disabled");
                $("#" + first1).attr("src", "Images/first_1.png");
                $("#" + prev1).removeAttr("disabled");
                $("#" + prev1).attr("src", "Images/prev_1.png");


                $("#" + first1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
                $("#" + prev1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");

            }
            else {
                $("#" + first1).attr("disabled", "disabled");
                $("#" + first1).attr("src", "Images/first_dis.png");
                $("#" + prev1).attr("disabled", "disabled");
                $("#" + prev1).attr("src", "Images/prev_dis.png");

                $("#" + first1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");
                $("#" + prev1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");

            }
            if (currPage1 >= numPages1) {
                $("#" + next1).attr("disabled", "disabled");
                $("#" + next1).attr("src", "Images/next_dis.png");
                $("#" + last1).attr("disabled", "disabled");
                $("#" + last1).attr("src", "Images/last_dis.png");

                $("#" + next1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");
                $("#" + last1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");

            }
            else {

                $("#" + next1).removeAttr("disabled");
                $("#" + next1).attr("src", "Images/next_1.png");
                $("#" + last1).removeAttr("disabled");
                $("#" + last1).attr("src", "Images/last_1.png");

                $("#" + next1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
                $("#" + last1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
            }
            $('body,html').animate({ scrollTop: 0 }, 800);

            if (typeof (tabbar) != "undefined") {
                invokeIframeResizer(tabbar.getActiveTab());
            }
            // Must reload the new page.   
            customParameters.loadPageFunction(newPage);
            //LoadCreatedSessionPage(newPage+1);
        });

        $("#" + prev1).bind("click", function () {

            var previous = $('#' + customParameters.divName + ' > a.selectedPage').prev('a');

            $('#' + customParameters.divName + ' > a.selectedPage').removeClass('selectedPage');

            $(previous).addClass('selectedPage');

            var rowsTotal = $('#' + customParameters.tbodyName + ' tr').length;
            var newPage = parseInt(currPage) - 1;
            var startItem = newPage * rowsShown;
            var endItem = parseInt(startItem) + parseInt(rowsShown);
            $('#' + customParameters.tbodyName + ' tr').hide();
            $('#' + customParameters.tbodyName + ' tr').slice(startItem, endItem).show();

            $('#' + customParameters.tbodyName + ' tr:first').parent().trigger('applyWidgets');


            var currPage1 = parseInt(currPage) - 1;
            currPage = parseInt(currPage) - 1;

            if (currPage1 >= 1) {
                $("#" + first1).removeAttr("disabled");
                $("#" + first1).attr("src", "Images/first_1.png");
                $("#" + prev1).removeAttr("disabled");
                $("#" + prev1).attr("src", "Images/prev_1.png");

                $("#" + first1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
                $("#" + prev1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");

            }
            else {
                $("#" + first1).attr("disabled", "disabled");
                $("#" + first1).attr("src", "Images/first_dis.png");
                $("#" + prev1).attr("disabled", "disabled");
                $("#" + prev1).attr("src", "Images/prev_dis.png");

                $("#" + first1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");
                $("#" + prev1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");

            }
            if (currPage1 >= numPages1) {
                $("#" + next1).attr("disabled", "disabled");
                $("#" + next1).attr("src", "Images/next_dis.png");
                $("#" + last1).attr("disabled", "disabled");
                $("#" + last1).attr("src", "Images/last_dis.png");

                $("#" + next1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");
                $("#" + last1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");

            }
            else {

                $("#" + next1).removeAttr("disabled");
                $("#" + next1).attr("src", "Images/next_1.png");
                $("#" + last1).removeAttr("disabled");
                $("#" + last1).attr("src", "Images/last_1.png");

                $("#" + next1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
                $("#" + last1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
            }
            $('body,html').animate({ scrollTop: 0 }, 800);
            if (typeof (tabbar) != "undefined") {
                invokeIframeResizer(tabbar.getActiveTab());
            }
            // Must reload the new page.  
            customParameters.loadPageFunction(newPage);
            //LoadCreatedSessionPage(newPage+1);
        });


        $("#" + first1).bind("click", function () {

            $('#' + customParameters.divName + ' > a.selectedPage').removeClass('selectedPage');

            $("#" + customParameters.divName + " a:first").addClass("selectedPage");

            var rowsTotal = $('#' + customParameters.tbodyName + ' tr').length;
            var newPage = 0;
            currPage = 0;
            var startItem = newPage * rowsShown;
            var endItem = parseInt(startItem) + parseInt(rowsShown);
            $('#' + customParameters.tbodyName + ' tr').hide();
            $('#' + customParameters.tbodyName + ' tr').slice(startItem, endItem).show();

            $('#' + customParameters.tbodyName + ' tr:first').parent().trigger('applyWidgets');


            var currPage1 = 0;

            if (currPage1 >= 1) {
                $("#" + first1).removeAttr("disabled");
                $("#" + first1).attr("src", "Images/first_1.png");
                $("#" + prev1).removeAttr("disabled");
                $("#" + prev1).attr("src", "Images/prev_1.png");

                $("#" + first1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
                $("#" + prev1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");

            }
            else {
                $("#" + first1).attr("disabled", "disabled");
                $("#" + first1).attr("src", "Images/first_dis.png");
                $("#" + prev1).attr("disabled", "disabled");
                $("#" + prev1).attr("src", "Images/prev_dis.png");

                $("#" + first1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");
                $("#" + prev1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");

            }
            if (currPage1 >= numPages1) {
                $("#" + next1).attr("disabled", "disabled");
                $("#" + next1).attr("src", "Images/next_dis.png");
                $("#" + last1).attr("disabled", "disabled");
                $("#" + last1).attr("src", "Images/last_dis.png");

                $("#" + next1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");
                $("#" + last1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");

            }
            else {

                $("#" + next1).removeAttr("disabled");
                $("#" + next1).attr("src", "Images/next_1.png");
                $("#" + last1).removeAttr("disabled");
                $("#" + last1).attr("src", "Images/last_1.png");

                $("#" + next1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
                $("#" + last1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
            }
            $('body,html').animate({ scrollTop: 0 }, 800);
            if (typeof (tabbar) != "undefined") {
                invokeIframeResizer(tabbar.getActiveTab());
            }
            // Must reload the new page. 
            customParameters.loadPageFunction(newPage + 1);
            //LoadCreatedSessionPage(newPage+1);
        });

        $("#" + last1).bind("click", function () {

            $('#' + customParameters.divName + ' > a.selectedPage').removeClass('selectedPage');

            $("#" + customParameters.divName + " a:last").addClass("selectedPage");
            
            var newPage = Math.ceil(rowsTotal / rowsShown);
            currPage = parseInt(newPage) - 1;
            var startItem = parseInt(newPage - 1) * rowsShown;
            var endItem = parseInt(startItem) + parseInt(rowsShown);
            $('#' + customParameters.tbodyName + ' tr').hide();
            $('#' + customParameters.tbodyName + ' tr').slice(startItem, endItem).show();

            $('#' + customParameters.tbodyName + ' tr:first').parent().trigger('applyWidgets');

            var currPage1 = parseInt(newPage);

            if (currPage1 >= 1) {
                $("#" + first1).removeAttr("disabled");
                $("#" + first1).attr("src", "Images/first_1.png");
                $("#" + prev1).removeAttr("disabled");
                $("#" + prev1).attr("src", "Images/prev_1.png");

                $("#" + first1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
                $("#" + prev1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");

            }
            else {
                $("#" + first1).attr("disabled", "disabled");
                $("#" + first1).attr("src", "Images/first_dis.png");
                $("#" + prev1).attr("disabled", "disabled");
                $("#" + prev1).attr("src", "Images/prev_dis.png");

                $("#" + first1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");
                $("#" + prev1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");

            }
            if (currPage1 >= numPages1) {
                $("#" + next1).attr("disabled", "disabled");
                $("#" + next1).attr("src", "Images/next_dis.png");
                $("#" + last1).attr("disabled", "disabled");
                $("#" + last1).attr("src", "Images/last_dis.png");

                $("#" + next1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");
                $("#" + last1).attr("style", "background:none; border:0;color:grey;font-size:12px;font-weight:bold;");

            }
            else {

                $("#" + next1).removeAttr("disabled");
                $("#" + next1).attr("src", "Images/next_1.png");
                $("#" + last1).removeAttr("disabled");
                $("#" + last1).attr("src", "Images/last_1.png");

                $("#" + next1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
                $("#" + last1).attr("style", "background:none; border:0;color:blue;font-size:12px;font-weight:bold;");
            }
            $('body,html').animate({ scrollTop: 0 }, 800);
            if (typeof (tabbar) != "undefined") {
                invokeIframeResizer(tabbar.getActiveTab());
            }
            // Must reload the new page.  
            customParameters.loadPageFunction(newPage);
            //LoadCreatedSessionPage(newPage);
        });

    }
    else if (rowsTotal > 0) {

        $(customParameters.navName).after("<div class='fieldset' id ='" + pageId +
                "'>" +

                "<div class='fieldset' id = '" + customParameters.mainDiv + "'><div class='table'><table cellpadding=\"0\" cellspacing=\"0\" class='pagingTable' id ='" + customParameters.tableName + "' align='center'  width='100%' class ='headerRow1'><tbody id='Tbody1' class ='headerRow'></tbody>" +
                "<tfoot><tr><td style='width:40%'></td>" +
            "<td>&nbsp;</td><td>&nbsp;</td>" +
            "<td style='width:30%' ></div></td><td>&nbsp;" +
            "</td>" +
            "<td style='width:30%;font-size:12px;font-weight:bold;'>No of Records: &nbsp;<select id='" + Norec + "' ><option value='" + pagingSize + "'>" + pagingSize + "</option><option value='" + (pagingSize * 2) + "'>" + (pagingSize * 2) + "</option><option value='" + (pagingSize * 4) + "'>" + (pagingSize * 4) + "</option></select></td></tr></tfoot></table></div></div>");
        $('#' + customParameters.tbodyName + ' tr').slice(0, rowsTotal).show();

        $('#' + customParameters.tbodyName + ' tr:first').parent().trigger('applyWidgets');

        //if (rowsTotal > noofrecs ) {

        $('#' + Norec + ' option[value=' + noofrecs + ']').attr('selected', true);

        //}


        if (pagingSize >= rowsTotal) {
            $("#" + Norec).attr("disabled", "disabled");
        }
    }
    else {
        //displayError(customParameters);

    }
    $("#" + Norec).bind("change", function () {
        var text1 = document.getElementById(Norec);
        noofrecs = text1.options[text1.selectedIndex].text;

        //$("#" + customParameters.mainDiv).hide();
        //$("#" + customParameters.mainDiv).remove();
        //$('#page0_SS').remove();
        $("#" + pageId).remove();

        pagingcommonNew(customParameters);
        $('.' + customParameters.sessiondatanamewithoutdot + " :first").show();

        $('#' + customParameters.tbodyName + ' tr:first').parent().trigger('applyWidgets');

        // Handle tab bar resizing.
        var obj = document.getElementById("a_tabbar");
        if (obj == null) {

            //do nothing at this time.
        }
        else {
            resizetab(tabbar.getActiveTab());

        }
        $('body,html').animate({ scrollTop: 0 }, 800);
        if (typeof (tabbar) != "undefined") {
            invokeIframeResizer(tabbar.getActiveTab());
        }
        // Must reload the new page. 
        customParameters.loadPageFunction(1);
        //LoadCreatedSessionPage(1);
    });                         // on change functionality for selection of records to display

    if (typeof (tabbar) != "undefined") {
        invokeIframeResizer(tabbar.getActiveTab());
    }
} // paging new
